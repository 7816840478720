// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQ2hOmwNaV7C_8KmuZFXc8CgOizLMeb6o",
  authDomain: "answarit-f3b0b.firebaseapp.com",
  projectId: "answarit-f3b0b",
  storageBucket: "answarit-f3b0b.appspot.com",
  messagingSenderId: "321754546768",
  appId: "1:321754546768:web:518498530d9ef514ea0755",
  measurementId: "G-C7L0R5LSPR",
};

export default firebaseConfig;
