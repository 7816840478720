import React from "react";

const TrainingTerms = () => {
  return (
    <div className="terms-of-use">
      <div className=" py-1 pt-3">
        <h4 className="title" style={{ textAlign: "justify" }}>
          {" "}
          <strong>
            শুরুতেই Answar IT ' তে আপনাকে স্বাগতম। আপনি এই ওয়েবসাইটের সকল টার্মস
            এবং কন্ডিশনস মেনে ওয়েবসাইটটি ব্যবহার করছেন অথবা করতে চাচ্ছেন বলে
            আমরা ধরে নিচ্ছি। এই ওয়েবসাইটে ব্যবহৃত “আমরা”, “আমাদের” বলতে মূলত
            “Answar IT” কর্তৃপক্ষকে বুঝানো হয়েছে। আপনার যদি কোনো টার্মস এবং
            কন্ডিশনস বুঝতে সমস্যা হয়ে থাকে তাহলে আপনি আমাদের ইমেইল অথবা ফেসবুক
            পেজে যোগাযোগ করতে পারেন। টার্মস এবং কন্ডিশনসের ব্যাপারে “Answar IT”
            কর্তৃপক্ষের সিদ্ধান্তই চূড়ান্ত এবং যেকোনো সময় তা পরিবর্তনের ক্ষমতা
            কর্তৃপক্ষের রয়েছে।
            <br />
            <br />
            টার্মস এবং কন্ডিশনস নিম্নরূপ:
          </strong>{" "}
        </h4>
      </div>

      <p>
        ১। আপনার অ্যাকাউন্ট ক্রেডেনশিয়ালস (Username & Password) একান্তই আপনার
        ব্যক্তিগত। এগুলো অন্য কোনো ব্যাক্তি/মাধ্যমের সাথে শেয়ার করা অনুমোদিত নয়।
        <br />
        <br />
        ২। আপনার অ্যাকাউন্ট ক্রেডেনশিয়ালস অন্য কোনো ব্যাক্তি/মাধ্যমের সাথে শেয়ার
        করা হলে আপনার অ্যাকাউন্ট যেকোনো সময় বিনা নোটিশে টার্মিনেট করা হতে পারে
        এবং সেক্ষেত্রে আপনি আর এই অ্যাকাউন্ট ব্যবহার করতে পারবেন না।
        <br />
        <br />
        ৩। কর্তৃপক্ষের অনুমতি ব্যতিত যে কোনো কনটেন্ট বা কোর্স এর অভিও/ভিডিও
        বিতরন সম্পূর্ণরূপে নিষিদ্ধ এবং আইনত দন্ডনীয় অপরাধ। আমরা আশা করি এবং
        বিশ্বাস করি, এ ব্যাপারে আপনি সর্বদা সতর্ক থাকবেন। এটি সামগ্রিক ভাবে
        সবাইকেই ক্ষতিগ্রস্থ করতে পারে।
        <br />
        <br />
        ৪। “Answar IT” এর কোর্স সম্পর্কিত যেকোনো ভিডিও, টেক্সট বা কনটেন্ট '
        Answar IT ' থেকে লিখিত অনুমতি ছাড়া অন্য কারও সাথে অর্থের বিনিময়ে বা
        বিনামূল্যে আদান-প্রদান বা শেয়ার করা আইনত দন্ডনীয় অপরাধ।
        <br />
        <br />
        ৫। ইউটিউব, গুগল ড্রাইভ, ফেইসবুক, কিংবা কোনো পেনড্রাইভ, ডিভিডি, সিডি বা
        অন্য কোন মাধ্যমে শেয়ার করলে “Answar IT” টিম বা “Answar IT এর আইনানুগ
        প্রতিনিধি" গণপ্রজাতন্ত্রী বাংলাদেশ সরকার এর কপিরাইট আইন, কপিরাইট এক্ট
        ২০০০, কপিরাইট এক্ট ২০০৫ সংশোধন: সেকশন ৮৪, ডিজিটাল কপিরাইট আইন, Digital
        Security Act, 2018, Digital Security Act, 2018 (সেকশন ১৯) এবং সাইবার
        সিকিউরিটি বা উপযুক্ত আইন অনুসারে আমার বিরুদ্ধে প্রয়োজনীয় পদক্ষেপ নিতে
        পারবেন।
        <br />
        <br />
        ৬। অনুগ্রহ করে “Answar IT” সাপোর্ট চ্যাট, গ্রুপ চ্যাট, ফেইসবুক গ্রুপ
        পোস্ট, কমেন্ট, ফেসবুক পেজ অথবা “Answar IT” সম্পর্কিত যেকোনো মাধ্যমে সব
        ধরণের ব্যাক্তিগত আক্রমন, রাজনৈতিক আলাপ এবং হিংসাত্মক কথাবার্তা থেকে বিরত
        থাকবেন। এরকম যেকোনো কাজের জন্য আপনাকে গ্রুপ চ্যাট এবং গ্রুপ মেম্বেরশিপ
        থেকে মিউট/বাতিল করে দেয়া হবে এবং পুনরাবৃত্তির ক্ষেত্রে আপনার অ্যাকাউন্ট
        যেকোনো সময় টার্মিনেট করা হতে পারে।
        <br />
        <br />
        ৭। কোর্সে এনরোল করার পূর্বে কোর্স কারিকুলাম ভালোভাবে দেখে নিবেন, অন্যথায়
        কোন অভিযোগ গ্রহন করা হবে না। আপনার কোর্স কারিকুলাম সম্পর্কিত কোনো অনুরোধ
        থাকলে আমাদের সাপোর্ট চ্যাট, ইমেইল অথবা ফেসবুক পেজে ম্যাসেজ দিয়ে জানাতে
        পারেন। আমরা আপনার সমস্যা সমাধানের চেষ্টা করব।
        <br />
        <br />
        ৮। ট্রেনিং চলাকালে শিক্ষা উপকরণ হিসেবে যে সকল ওয়েবসাইট লিঙ্ক, ভিডিও
        লিঙ্ক, ব্লগ লিঙ্ক গুলো প্রদান করা হবে তা কেবলমাত্র আপনাদের অনুশীলনের
        স্বার্থে। সেগুলো গুরুত্ব সহকারে দেখতে হবে।
        <br />
        <br />
        ৯। আপনারা অবশ্যই গ্রুপ চ্যাট, ফেইসবুক গ্রুপ পোস্ট, কমেন্ট, ফোরাম অথবা
        ফেসবুক পেজে সকল প্রকার প্রমোশনাল পোস্ট, স্প্যামিং থেকে বিরত থাকুন। এরকম
        যেকোনো কাজের জন্য আপনাকে গ্রুপ চ্যাট এবং গ্রুপ মেম্বাররশিপ থেকে
        মিউট/বাতিল করে দেয়া হবে এবং পুনরাবৃত্তির ক্ষেত্রে আপনার অ্যাকাউন্ট
        যেকোনো সময় টার্মিনেট করা হতে পারে।
        <br />
        <br />
        ১০। সকল প্রকারের পেমেন্ট সক্রান্ত বিষয়ের জন্য “Answar IT” সিদ্ধান্ত
        চূড়ান্ত বলে গন্য হবে। পেমেন্ট করার পূর্বে অবশ্যই কোর্সের মূল্য এবং
        মাধ্যম ভালো ভাবে দেখে নিবেন। আমরা বিকাশ (পারসোনাল এবং মার্চেন্ট) এর
        মাধ্যমে পেমেন্ট গ্রহন করে থাকি। এই সংক্রান্ত যে কোন জিজ্ঞাসা আমাদের
        প্রদত্ত নাম্বার, সাপোর্ট, ইমেইল অথবা ফেসবুক পেজে যোগাযোগ করতে পারেন।
      </p>

      <br />
      <h4 className="title" style={{ textAlign: "justify" }}>
        <strong>
          আশা করছি, উপরে আমাদের কোম্পানীর ট্রেনিং এর টার্মস এবং কন্ডিশনসগুলো
          মেনে চলবেন এবং পরিপূর্ণভাবে কোর্সটি সম্পন্ন করবেন। বিঃ দ্রঃ কোর্সটি
          শুধুমাত্র বাংলাদেশী নাগরিকগন করতে পারবেন।
        </strong>{" "}
      </h4>
      <hr></hr>
    </div>
  );
};

export default TrainingTerms;
